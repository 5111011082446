function setEqualHeight() {
    var skelSize = getNumSkelSize();
    $(".grid-menu-item .grid-element-title").matchHeight();
    $(".grid-menu-item .grid-element-content-detail").matchHeight();
    if (skelSize > 3) {
        $(".cenik .grid-element-content").matchHeight({
            target: $("#cenik-wrapper")
        });
    } else {
        $(".cenik .grid-element-content").css("height", "");
    }
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        scroll_limit = 40,
        initial_logo_height = 192,
        initial_header_height = 40,
        logo_scale_faktor = 0.6,
        logo_height,
        header_height;
    switch (current_breakpoint) {
        case "large":
            scroll_limit = 40;
            initial_logo_height = 168;
            initial_header_height = 40;
            break;
        case "medium":
            scroll_limit = 30;
            initial_logo_height = 144;
            initial_header_height = 30;
            logo_scale_faktor = 1;
            break;
        case "small":
            scroll_limit = 30;
            initial_logo_height = 144;
            initial_header_height = 30;
            break;
        case "xsmall":
            scroll_limit = 50;
            initial_logo_height = 120;
            initial_header_height = 50;
            logo_scale_faktor = 0.5;
            break;
        default:
            scroll_limit = 80;
            initial_height = 120;
    }
    if ($(window).scrollTop() <= scroll_limit) {
        header_height = initial_header_height - $(window).scrollTop();
        logo_height = initial_logo_height - $(window).scrollTop() * logo_scale_faktor;
        margin_top = -$(window).scrollTop();
    } else {
        header_height = initial_header_height - scroll_limit;
        logo_height = initial_logo_height - scroll_limit * logo_scale_faktor;
        margin_top = -initial_header_height;
    }
    $("#header-main").css("height", header_height + "px");
    $("#header-right").css("margin-top", margin_top + "px");
    $("#logo a").css("height", logo_height + "px");
    $("#logo a").css("width", logo_height + "px");
});
